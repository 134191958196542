
import { Component, Vue } from 'vue-property-decorator';
import { RawLocation } from 'vue-router';
import FullScreenForm from '@/components/FullScreenForm.vue';
import GButton from '@/components/gsk-components/GskButton.vue';
import GTextfield from '@/components/gsk-components/GskTextfield.vue';
import GSelect from '@/components/gsk-components/GskSelect.vue';
import { ProjectDetailsModule } from '@/store/modules/project-details.module';
import { openSnackbar } from '@/utils/components';
import { TextfieldInfo } from '@/constants';
import GAnalytics from '@/components/GAnalytics';
import { addAnalyticsRouteParams, ClickData } from '@/analytics';
import { EnumsModule } from '@/store/modules/enums.module';
import GRadioGroup from '@/components/gsk-components/GskRadioGroup.vue';
import { cleanOutSpecialCharAndTags } from '@/utils/routing';
import * as Projects from '@/types/projects.types';
import * as API from '@/api/projects.api';
import { FeatureFlagsModule } from '@/store/modules/feature-flags.module';
import { ProcessEnvsModule } from '@/store/modules/process-envs.module';
import { filter, get, omit, identity } from 'lodash';
import { AutocompleteBase } from '@gsk-tech/gsk-autocomplete/gsk-autocomplete-base';
import HelpTooltip from '@/components/HelpTooltip.vue';


@Component({
  components: {
    FullScreenForm,
    GButton,
    GTextfield,
    GAnalytics,
    GSelect,
    GRadioGroup,
    HelpTooltip
  },
})
export default class ProjectDetailsUpdateView extends Vue {
  private initialProjectName = '';
  private initialProjectDescription = '';
  private projectName = '';
  private projectDescription = '';
  private initialBusinessUnit = '';
  private businessUnit = '';
  private businessSubUnit = '';
  private initialBusinessSubUnit = '';
  private oauthType = '';
  public nameLabel: string = TextfieldInfo.nameLabel;
  public namePlaceholder: string = TextfieldInfo.namePlaceholder;
  public nameValidationMessage: string = TextfieldInfo.nameValidationMessage;
  public descriptionLabel: string = TextfieldInfo.descriptionLabel;
  public descriptionPlaceholder: string = TextfieldInfo.descriptionPlaceholder;
  public descriptionValidationMessage: string = TextfieldInfo.descriptionValidationMessage;
  private budgetingSource :Projects.BudgetingSource | string  = '';
  private initialBudgetingSource :Projects.BudgetingSource | string = '';


  get loading(): boolean {
    return ProjectDetailsModule.projectLoading;
  }

  get options() {
    return EnumsModule.businessUnitFormOptions;
  }

  async save(sendClick: () => void): Promise<void> {
    sendClick();
    
    try {
      await ProjectDetailsModule.updateProjectDetails({
        projectId: this.$route.params.id,
        projectName: cleanOutSpecialCharAndTags(this.projectName),
        projectDescription: this.projectDescription,
        businessUnitId: this.businessUnit,
        businessSubUnit: this.businessSubUnit,
        ...((this.budgetingSource ?true:false) && { "budgetingSource": this.budgetingSource })
      });
      if (typeof this.$route.query.from === 'string') {
        this.$router.push(this.$route.query.from).catch(e => this.$logger.warn(e));
      } else {
        this.$router.push(this.projectRoute).catch(e => this.$logger.warn(e));
      }
    } catch (e) {
      openSnackbar.call(this, 'Server Error: Could not save project details');
    }
  }

  close(): void {
    this.$router.replace(this.projectRoute).catch(e => this.$logger.warn(e));
  }

  get showCancel(): boolean {
    if (!this.projectName || this.businessUnit === '') {
      return false;
    } 
    return (
      this.initialProjectDescription !== this.projectDescription ||
      this.initialProjectName !== this.projectName ||
      this.initialBusinessUnit !== this.businessUnit ||
      this.initialBusinessSubUnit !== this.businessSubUnit ||
      this.initialBudgetingSource !== this.budgetingSource
    );
  }

  get disabled(): boolean {
    return ProjectDetailsModule.projectLoading || this.loading;
  }

  get analyticsData(): ClickData {
    return {
      clickTarget: 'project-details-form-submit',
      projectName: this.projectName,
      projectId: +this.$route.params.id,
    };
  }

  get isNotRPAProject(){
    const env = ProjectDetailsModule.defaultEnv;
    return env?env.environmentTypeMnemonic!=='RPA': true;
  }

  get projectRoute(): RawLocation {
    const { id } = this.$route.params;
    return {
      name: 'project-details',
      params: addAnalyticsRouteParams(
        { id },
        {
          projectName: this.projectName,
          projectId: id,
        },
      ),
    };
  }

  async created(): Promise<void> {
    if (ProjectDetailsModule.projectDetails.projectId === +this.$route.params.id) {
      this.$log('populated form without loading fresh data');
    } else {
      this.$log('populated form by loading fresh data');
      await ProjectDetailsModule.getProject(this.$route.params.id);
    }

    this.initialProjectName = ProjectDetailsModule.projectDetails.projectName;
    this.projectName = ProjectDetailsModule.projectDetails.projectName;
    this.initialProjectDescription = ProjectDetailsModule.projectDetails.projectDescription;
    this.projectDescription = ProjectDetailsModule.projectDetails.projectDescription;
    if (ProjectDetailsModule.projectDetails.businessUnitId) {
      this.initialBusinessUnit = ProjectDetailsModule.projectDetails.businessUnitId;
      this.businessUnit = ProjectDetailsModule.projectDetails.businessUnitId;
    }
    if (ProjectDetailsModule.projectDetails.businessSubUnit) {
      this.initialBusinessSubUnit = ProjectDetailsModule.projectDetails.businessSubUnit;
      this.businessSubUnit = ProjectDetailsModule.projectDetails.businessSubUnit;
    }
    if (ProjectDetailsModule.projectDetails.budgetingSource) {
      this.initialBudgetingSource = ProjectDetailsModule.projectDetails.budgetingSource;
      this.budgetingSource = ProjectDetailsModule.projectDetails.budgetingSource;
    }
  }
  
  get isBudgetingSourceEnabled(): boolean {
    return FeatureFlagsModule.budgetingSourceEnabled;
  }
  async getBudgetingSourceChoices(query: string): Promise<Projects.BudgetingSourceLabel[]> {
    let choices;
    try {
      choices = await API.getBudgetingSourceList(query);
    } catch(e) {
      //
    }

    const choiceData  = choices? choices.data : [];
    return choiceData.map(res => ({
      label: `${res.id} - ${res.name}`,
      value: res.id,
      name: res.name,
      person: res.person,
      type: res.type,
      id: res.id,
    }));
  }
  get budgetingSourceTooltipMessage() {
      return ProcessEnvsModule.processEnvs.budgetingSourceTooltipText;
  }
  async setBudgetingSource(e: CustomEvent) {
    this.budgetingSource = e.detail.selectedChoice ? omit(e.detail.selectedChoice,['label','value']) as Projects.BudgetingSource : '';
  }

  async setInitialBudgetingSource() {
    if(!this.budgetingSource){
      return false;
    }
    const latestBudgetingSource = this.budgetingSource;
    const autoComplete = this.$refs.budgetingSource as AutocompleteBase;
    const choices = await this.getBudgetingSourceChoices(latestBudgetingSource?.id || '');
    autoComplete.choices = choices;
    await autoComplete.updateComplete;
    autoComplete.value = latestBudgetingSource?.id || '';
    this.setBudgetingSource(new CustomEvent('change', { detail: {
        selectedChoice: choices.find(choice => choice.value === latestBudgetingSource?.id),
      },
    }));
  }

  mounted() {
    if (FeatureFlagsModule.budgetingSourceEnabled) {
      this.setInitialBudgetingSource();
    }
  }

}
